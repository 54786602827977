import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64ce4333"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 1,
  class: "bo-list-item flex align-items-center cursor-pointer text-color px-3 py-2"
}
const _hoisted_4 = { class: "bo-list-item-content" }
const _hoisted_5 = {
  key: 1,
  class: "pi pi-angle-down text-primary ml-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarMenuItemIcon = _resolveComponent("SidebarMenuItemIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.item?.route)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        class: "bo-list-item",
        "active-class": "router-link-active router-link-exact-active",
        to: _ctx.RouteHelper.getLink(_ctx.item.route)
      }, {
        default: _withCtx(() => [
          (_ctx.item.icon)
            ? (_openBlock(), _createBlock(_component_SidebarMenuItemIcon, {
                key: 0,
                color: _ctx.item?.iconColor,
                family: _ctx.item?.iconFamily,
                icon: _ctx.item?.icon,
                sharp: _ctx.item?.iconSharp
              }, null, 8, ["color", "family", "icon", "sharp"]))
            : (_ctx.item.iconPath)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  width: "28",
                  class: "image",
                  src: _ctx.item?.iconPath
                }, null, 8, _hoisted_1))
              : _createCommentVNode("", true),
          _createElementVNode("span", {
            class: "bo-list-item-content",
            innerHTML: _ctx.$t(_ctx.item?.label as string)
          }, null, 8, _hoisted_2)
        ]),
        _: 1
      }, 8, ["to"]))
    : (_openBlock(), _createElementBlock("li", _hoisted_3, [
        (_ctx.item?.icon)
          ? (_openBlock(), _createBlock(_component_SidebarMenuItemIcon, {
              key: 0,
              color: _ctx.item?.iconColor,
              family: _ctx.item.iconFamily,
              icon: _ctx.item.icon,
              sharp: _ctx.item.iconSharp
            }, null, 8, ["color", "family", "icon", "sharp"]))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.item?.label), 1),
        (!!_ctx.item?.items?.length)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5))
          : _createCommentVNode("", true)
      ]))
}